export const riskScoreList = {
  0: "#d3d3d3",
  1: "#70AC3F",
  2: "#9FCE7C",
  3: "#C1D75D",
  4: "#D7E98B",
  5: "#FCD630",
  6: "#F8C65A",
  7: "#F69632",
  8: "#F17238",
  9: "#F7434A",
  10: "#F1104E",
}