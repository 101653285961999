export function calculateRiskLevel(score) {
  if (typeof score === 'string' && score.trim() !== '') {
    score = Number(score)
  }

  if (typeof score !== 'number' || isNaN(score)) {
    return null
  }

  if (score >= 0 && score <= 29) {
    return 'low'
  } else if (score >= 30 && score <= 69) {
    return 'medium'
  } else if (score >= 70 && score <= 100) {
    return 'high'
  } else {
    return null
  }
}