export const PaymentStatusColorMap = {
    'pending': '#FBA119',
    'succeed': 'green',
    'reject': '#F1104E',
    'def': '',
}

export const ReportPackagesStatusMap = {
    'Not Identified': 'failure',
    'SoF Not identified': 'failure',
    'No information': 'failure',
    'Some of SoF is identified': 'some',
    'All information': 'warning',
  }