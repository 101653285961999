<template>
  <div class="progress-bar-wrapper">
    <div class="progress-bar-top-label">
      {{ topLabel }}
    </div>
    <div class="progress-bar-total flex justify-center">
      <VueSkeletonLoader
        v-if="calculationLoading"
        animation="wave"
        class="pie-circle-loader"
        type="circle"
      />
      <div
        v-else
        class="risk-score-value"
        :style="`color: ${findColorByTypeScore(
          hasFundsData ? totalFunds : -1,
        )}`"
      >
        {{ hasFundsData ? formatFunds(totalFunds, false) : '-' }}
      </div>
    </div>
    <div :class="{ 'progress-bar-loading': calculationLoading }">
      <progress-bar
        class="mb-2"
        :height="progressBarHeight"
        :percentage="Number(totalFunds.toFixed(0))"
      />
      <div class="progress-bar-bottom-text flex fs-14 m-fs-12">
        <div class="flex-1 m-text-center mr-3">
          Low Risk Score
        </div>
        <div class="flex-1 text-center ml-3 mr-3">
          Mid Risk Score
        </div>
        <div class="flex-1 flex flex-end ml-3 text-center">
          High Risk Score
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { formatFunds } from '@/utils/report-data-formatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
// Libs
import 'vue-pithy-progress/lib/progress-bar.css'
import progressBar from 'vue-pithy-progress/lib/progress-bar.umd.min.js'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    progressBar,
    VueSkeletonLoader,
  },
  props: {
    topLabel: {
      type: String,
      default: 'Risk Score',
    },
    totalFunds: {
      type: [Number, String],
      default: 0,
    },
    hasFundsData: {
      type: Boolean,
      default: true,
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
    progressBarHeight: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    hex2rgba,
    formatFunds,
    findColorByTypeScore,
  },
}
</script>

<style>
.progress-bar-top-label {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: var(--light-grey-59);
  margin-bottom: 16px;
}
.progress-bar-bottom-text {
  color: var(--light-grey-59);
}
.progress-bar-total {
  margin-bottom: 24px;
}
.progress-bar-wrapper .pie-circle-loader {
  border-radius: 50% !important;
  width: 64px !important;
  height: 64px !important;
}

.text-end-last {
  text-align-last: end;
}

.a-progress-bar-wrapper .progress-outside {
  background: linear-gradient(
    90deg,
    #70ac3f 0%,
    #ffe673 48.96%,
    #ff2a00 100%
  ) !important;
}
.progress-bar-loading .a-progress-bar-wrapper .progress-outside {
  background: linear-gradient(
    90deg,
    #e7e7e7 0%,
    #3f3f3f 48.96%,
    #000000 100%
  ) !important;
}

.a-progress-bar-wrapper .progress-inner {
  background: transparent !important;
}

.a-progress-bar-wrapper .progress-outside .progress-inner .bar-tip {
  font-size: 0;
  background: transparent !important;
}

.a-progress-bar-wrapper .progress-outside .progress-inner .bar-tip:after {
  border-top: 10px solid var(--dark-grey-6-e);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  right: 5%;
  bottom: -3px;
}

.total-funds-report {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translate(-50%);
}

.risk-score-value {
  text-align: center;
  font-size: 64px;
  line-height: 64px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .risk-score-value {
    font-size: 54px;
    line-height: 54px;
  }
}
</style>
