<template>
  <div 
    class="token-report__item flex align-center gap-2" 
    :class="{'token-report__item--active' : isActive}"
    @click="clickOnItem"
  >
    <div
      v-if="token.icon"
      class="token-report__item-icon" 
    >
      <img 
        class="display-block"
        height="24px" 
        :src="`data:image/png;base64, ${token.icon}`" 
        width="24px"
      >
    </div>
    <div 
      v-else-if="!token.icon && !token.address"
      class="token-report__item-icon"
    >
      <EthIcon v-if="token.symbol == 'ETH'" />
      <TronIcon v-else-if="token.symbol == 'TRX'" />
    </div>
    <div class="token-report__item-label fs-18 ellipsis">
      {{ token.symbol }}
    </div>
  </div>
</template>

<script>
import EthIcon from './icons/EthIcon.vue'
import TronIcon from './icons/TronIcon.vue'

export default {
  components: {
    EthIcon,
    TronIcon,
  },
  props: {
    token: {
      type: Object,
      default() {
        () => []
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    clickOnItem() {
      !this.isActive && this.$emit('clickOnItem', this.token)
    }
  },
}
</script>

<style scoped>
.token-report__item {
  max-width: 200px;
  height: 38px;
  padding: 0 8px;
  border: 1px solid var(--reflex-bluet);
  border-radius: 18px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
  user-select: none;
}
.token-report__item-icon {
  position: relative;
  z-index: 2;
}
.token-report__item-label {
  color: var(--reflex-bluet);
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
}
.token-report__item--active {
  background-color: var(--reflex-bluet);
}
.token-report__item:not(.token-report__item--active):hover {
  background-color: var(--reflex-bluet-transparent);
}
.token-report__item--active .token-report__item-label {
  color: var(--white);
}
</style>