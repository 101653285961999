var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gl-scoring-bar-chart-wrapper",class:{ 'demo-for-landing' : _vm.demoForLanding}},[_c('transition-group',{attrs:{"name":"trans-for-bars","tag":"div"}},_vm._l((_vm.resultData),function(item,index){return _c('div',{key:index,class:_vm.classesForBarChartRow(item.score),on:{"click":function($event){return _vm.barClick(item)}}},[_c('div',{staticClass:"gl-scoring-bar-chart-row",class:{
          'gl-scoring-bar-chart-row--active' : item.name === _vm.activeBarItem.name,
        }},[(_vm.isMobile)?_c('div',{staticClass:"flex space-between gap-2"},[_c('div',{staticClass:"gl-scoring-bar-chart-cell-name"},[_c('BadgeInfo',{attrs:{"text":item.name,"value":item.score}})],1),_c('div',{staticClass:"gl-scoring-bar-chart-cell-amount-wrapper fs-12 bold break-word mt-1 m-fs-10"},[_c('div',{staticClass:"gl-scoring-bar-chart-cell-amount"},[_c('div',[_vm._v(" "+_vm._s(_vm.dataForAmount(item))+" ")])]),_c('div',{staticClass:"gl-scoring-bar-chart-cell-amount"},[_c('div',{staticClass:"fs-10 price m-fs-8"},[_vm._v(" "+_vm._s(_vm.dataForPrice(item))+" ")])])])]):_vm._e(),_c('div',{staticClass:"gl-scoring-bar-chart-bar-wrapper"},[_c('div',{staticClass:"type-entity-label-wrapper",style:({
              minWidth: ("" + (_vm.getLabelBarWidth(
                item.share,
                _vm.basisGraphWidth,
                _vm.basisPercentGraphWidth
              ))),
              maxWidth: ("" + (_vm.getLabelBarWidth(
                item.share,
                _vm.basisGraphWidth,
                _vm.basisPercentGraphWidth
              ))),
            })},[(!_vm.isMobile)?_c('div',{staticClass:"gl-scoring-bar-chart-cell-name"},[_c('BadgeInfo',{attrs:{"text":item.name,"value":item.score}})],1):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"gl-scoring-bar-chart-cell-amount-wrapper fs-12 bold break-word ma-2 m-fs-10"},[_c('div',{staticClass:"gl-scoring-bar-chart-cell-amount"},[_c('div',[_vm._v(" "+_vm._s(_vm.dataForAmount(item))+" ")])]),_c('div',{staticClass:"gl-scoring-bar-chart-cell-amount"},[_c('div',{staticClass:"fs-10 price m-fs-8"},[_vm._v(" "+_vm._s(_vm.dataForPrice(item))+" ")])])]):_vm._e()]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: "indirect",
              show: _vm.hoveredIndirectTooltipItem === index,
              trigger: 'manual',
              boundariesElement: 'body',
            }),expression:"{\n              content: `indirect`,\n              show: hoveredIndirectTooltipItem === index,\n              trigger: 'manual',\n              boundariesElement: 'body',\n            }"}],staticClass:"type-entity-bar-indirect",style:({
              minWidth: ("" + (_vm.getProgressBarIndirectWidth(
                item.share,
                item.deepDepthShare,
                item.generalShare,
                _vm.basisGraphWidth,
                _vm.basisPercentGraphWidth
              ))),
              maxWidth: ("" + (_vm.getProgressBarIndirectWidth(
                item.share,
                item.deepDepthShare,
                item.generalShare,
                _vm.basisGraphWidth,
                _vm.basisPercentGraphWidth
              ))),
            }),on:{"mouseleave":function($event){_vm.hoveredIndirectTooltipItem = null},"mouseover":function($event){_vm.hoveredIndirectTooltipItem = index}}},[_c('div',{staticClass:"bold-600 pl-2 pr-2 main-text"},[_vm._v(" "+_vm._s(item.deepDepthShare > 0 ? _vm.formatShare(_vm.roundShare(item.deepDepthShare)) : item.generalShare > 0 ? '' : _vm.SMALL_PERCENT_LABEL)+" ")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: "direct",
              show: _vm.hoveredDirectTooltipItem === index,
              trigger: 'manual',
              boundariesElement: 'body',
            }),expression:"{\n              content: `direct`,\n              show: hoveredDirectTooltipItem === index,\n              trigger: 'manual',\n              boundariesElement: 'body',\n            }"}],staticClass:"type-entity-bar-direct",style:({
              minWidth: ("" + (_vm.getProgressBarDirectWidth(
                item.share,
                item.generalShare,
                item.deepDepthShare,
                _vm.basisGraphWidth,
                _vm.basisPercentGraphWidth
              ))),
              maxWidth: ("" + (_vm.getProgressBarDirectWidth(
                item.share,
                item.generalShare,
                item.deepDepthShare,
                _vm.basisGraphWidth,
                _vm.basisPercentGraphWidth
              ))),
            }),on:{"mouseleave":function($event){_vm.hoveredDirectTooltipItem = null},"mouseover":function($event){_vm.hoveredDirectTooltipItem = index}}},[_c('div',{staticClass:"bold-600 pl-2 pr-2 main-text"},[_vm._v(" "+_vm._s(item.generalShare > 0 ? _vm.formatShare(_vm.roundShare(item.generalShare)) : item.deepDepthShare > 0 ? '' : _vm.SMALL_PERCENT_LABEL)+" ")])])]),_c('div',{staticClass:"gl-scoring-bar-chart-cntrl"},[_c('GlArrow',{staticClass:"gl-scoring-bar-chart-cntrl-arrow"}),_c('GlClose',{staticClass:"gl-scoring-bar-chart-cntrl-close"})],1)])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }