<template>
  <gl-modal
    v-bind="$attrs"
    class="token-report-modal"
    closable
    full-buttons
    :max-height-form="windowHeight < 650 ? '40vh' : '70dvh'"
    :overflowing-form="windowHeight < 650"
    submit-title="Select"
    title="Choose a Token"
    width="480"
    v-on="$listeners"
    @close="closeChooseModal"
    @submit="confirm"
  >
    <template #mainSlot>
      <Dropdown 
        v-model="selectedToken"
        class="choose-token-dropdown"
        :class="{ 'choose-token-dropdown--open' : dropdownState }"
        option-label="address"
        :options="filteredTokens" 
        placeholder="Select a Token"
        @hide="dropdownHide"
        @show="dropdownShow"
      >
        <template #value="slotProps">
          <div 
            v-if="slotProps.value"
            class="flex align-center"
          >
            <img 
              v-if="slotProps.value.icon"
              class="display-block mr-2"
              height="24px" 
              :src="`data:image/png;base64, ${slotProps.value.icon}`" 
              width="24px"
            >
            <div 
              v-else-if="!slotProps.value.icon && !slotProps.value.address"
              class="token-report__item-icon"
            >
              <EthIcon 
                v-if="slotProps.value.symbol == 'ETH'"
                class="display-block mr-2"
              />
              <TronIcon 
                v-else-if="slotProps.value.symbol == 'TRX'"
                class="display-block mr-2" 
              />
            </div>
            <div>{{ slotProps.value.symbol }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
          <input 
            v-model="filterQuery" 
            class="choose-token-dropdown__filter-input"
            placeholder="Type to filter tokens" 
            type="text"
          >
        </template>
        <template #option="slotProps">
          <div>
            <div class="flex align-center mb-1">
              <img 
                v-if="slotProps.option.icon"
                class="display-block mr-2"
                height="24px" 
                :src="`data:image/png;base64, ${slotProps.option.icon}`" 
                width="24px"
              >
              <div 
                v-else-if="!slotProps.option.icon && !slotProps.option.address"
                class="token-report__item-icon"
              >
                <EthIcon 
                  v-if="slotProps.option.symbol == 'ETH'"
                  class="display-block mr-2"
                />
                <TronIcon 
                  v-else-if="slotProps.option.symbol == 'TRX'"
                  class="display-block mr-2" 
                />
              </div>
              <div class="choose-token-dropdown__symbol">
                {{ slotProps.option.symbol }}
              </div>
            </div>
            <div class="choose-token-dropdown__address ellipsis">
              {{ slotProps.option.address }}
            </div>
          </div>
        </template>
      </Dropdown>
      <div class="choose-token-dropdown__checkbox-block pt-3">
        <div class="fs-16 bold-700 mb-2">
          Contracts filter
        </div>
        <div class="flex gap-3">
          <div 
            v-for="option in filterCheckboxes"
            :key="option.value"
            class="choose-token-dropdown__checkbox-item flex align-start fs-12 pointer" 
          >
            <input 
              :id="option.value" 
              v-model="selectedFilterCheckbox"
              class="mr-2 pointer"
              type="checkbox" 
              :value="option.value" 
            >
            <label 
              class="pointer"
              :for="option.value"
            >
              {{ option.label }}
            </label>
          </div>
        </div>
      </div>
    </template>
  </gl-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import GlModal from '@/components/gl-modal'
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import Dropdown from 'primevue/dropdown'
import EthIcon from './icons/EthIcon.vue'
import TronIcon from './icons/TronIcon.vue'

export default {
  components: {
    GlModal,
    Dropdown,
    EthIcon,
    TronIcon,
  },
  mixins: [deviceWidthMixin],
  props: {
    allTokens: {
      type: Array,
      default() {
        () => []
      }
    },
  },
  data() {
    return {
      filterQuery: '',
      filterCheckboxes: [
        { value: 'stableCoin', label: 'Stable Coins' },
        { value: 'regularContract', label: 'Regular Contracts' },
        { value: 'unknownContract', label: 'Unknown Contracts' },
      ],
      selectedFilterCheckbox: ['stableCoin', 'regularContract'],
      selectedToken: null,
      dropdownState: false,
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveToken']),
    filteredTokens() {
      if (this.selectedFilterCheckbox.length === 0) {
        return []
      } else {
        const filterByCheckboxes = this.allTokens.filter(item => {
          if (this.selectedFilterCheckbox.includes('stableCoin') && item.stableCoin === true) {
            return true
          }
          if (this.selectedFilterCheckbox.includes('unknownContract') && item.unknownContract === true) {
            return true
          }
          if (this.selectedFilterCheckbox.includes('regularContract') && !item.stableCoin && !item.unknownContract) {
            return true
          }
          return false
        });

        const query = this.filterQuery.toLowerCase()

        return filterByCheckboxes.filter(token => {
          return token.symbol.toLowerCase().includes(query) || 
                token.address.toLowerCase().includes(query)
        });
      }
    }
  },
  watch: {
    getActiveToken: {
      handler(newToken) {
        this.selectedToken = newToken
      },
      immediate: true,
    }
  },
  methods: {
    confirm() {
      this.$emit('chooseTokenFromModal', this.selectedToken)
    },
    dropdownHide() {
      this.filterQuery = ''
      this.dropdownState = false
    },
    dropdownShow() {
      this.dropdownState = true
    },
    closeChooseModal() {
      this.dropdownState = false
      this.$emit('closeChooseModal')
    }
  },
}
</script>

<style scoped>
.choose-token-dropdown {
  width: 100%;
}
.choose-token-dropdown__filter-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: var(--dark-grey-6-e);
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.choose-token-dropdown__filter-input::placeholder {
  color: var(--light-grey-blue);
}
.choose-token-dropdown--open .choose-token-dropdown__filter-input {
  opacity: 1;
  pointer-events: all;
}

.token-report-modal::v-deep .form-content-wrapper {
  padding: 16px;
  border-bottom: 1px solid var(--pale-blue);
}
.token-report-modal::v-deep .p-dropdown-label {
  position: relative;
}
.token-report-modal::v-deep .p-dropdown.p-focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}
.token-report-modal::v-deep .form-content-wrapper__overflowing {
  overflow: visible;
}
.token-report-modal::v-deep .p-dropdown .p-dropdown-panel {
  width: 100%;
}
.token-report-modal::v-deep .p-dropdown-item {
  padding: 8px;
}
.token-report-modal::v-deep .p-dropdown-item + .p-dropdown-item {
  border-top: 1px solid var(--pale-blue);
}
.token-report-modal::v-deep .p-dropdown-item .choose-token-dropdown__symbol {
  font-size: 15px;
}
.token-report-modal::v-deep .p-dropdown-item .choose-token-dropdown__address {
  font-size: 12px;
  color: var(--dark-grey-6-e);
}
.token-report-modal::v-deep .p-dropdown-items:empty::after {
  content: "Sorry, no matching options.";
  padding-left: 8px;
}

@media (max-width: 767px) {
  .token-report-modal::v-deep .gl-modal__container {
    overflow: visible;
  }
}
</style>