<template>
  <div class="gl-popover-hint">
    <v-popover
      auto-hide
      :boundaries-element="boundariesElement"
      offset="32"
      :open="value"
      placement="top"
    >
      <gl-icon
        class="ml-2 mt-1"
        :height="16"
        name="info-icon"
        :width="16"
        @click="innerValue = true"
      />
      <template slot="popover">
        <div class="gl-popover-hint-content-wrapper">
          <div class="flex space-between gap-3 fs-14 bold-700 mb-2">
            <div> {{ headText }} </div>
            <div>
              <gl-icon
                class="pointer"
                :height="12"
                name="close"
                :width="12"
                @click="innerValue = false"
              />
            </div>
          </div>
          <div class="gl-popover-hint-content-body-wrapper">
            <slot name="content-body" />
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'

export default {
  name: 'GlPopoverHint',
  components: {
    GlIcon,
  },

  props: {
    headText: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    boundariesElement: {
      type: HTMLDivElement,
      default: false,
    },
  },
  data() {
    return {}
  },

  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('input', e)
      },
    },
  },
}
</script>

<style>
.gl-popover-hint {
  display: flex;
  justify-content: center;
}
</style>
