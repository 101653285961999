<template>
  <div class="flex align-center m-fs-14">
    <gl-link
      v-if="url && isValidURL(url)"
      class="flex bold fs-18 m-fs-14"
      decoration-none
      :url="url"
    >
      <gl-icon
        class="mr-2"
        :height="isMobile ? 16 : 20"
        :name="iconName"
        :width="isMobile ? 16 : 20"
      />{{ label || url }}
    </gl-link>
    <div
      v-else
      :class="{ 'ellipsis max-w-300 m-max-w-200': ellipsis }"
    >
      {{ url || '–' }}
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlLink from '@/components/gl-link'
// utils
import { isValidURL } from '@/utils/text-formatter'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlIcon,
    GlLink,
  },
  mixins: [deviceWidthMixin],
  props: {
    url: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: 'open-link',
    },
    label: {
      type: String,
      default: '',
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isValidURL,
  },
}
</script>
