<template>
  <div>
    <ReportSectionItem
      class="mb-4"
      :label="labelTop"
    >
      <div class="fs-18 main-text bold m-fs-14">
        {{ ownerName }}
      </div>
    </ReportSectionItem>
    <ReportSectionItem
      v-if="typeData.name"
      class="mb-4"
      label="Type"
      :sub-label="labelType"
    >
      <div>
        <ReportTagMetaBlock
          hide-second-block
          :meta-data="typeData"
        />
      </div>
    </ReportSectionItem>
    <ReportSectionItem
      v-if="dataDescription"
      class="mb-2"
      label="Description"
    >
      <div>
        <ReportTagMetaBlock :data-description="dataDescription" />
      </div>
    </ReportSectionItem>
    <template v-if="tagMetaData && tagMetaData.length">
      <hr class="mt-4 mb-4 pale-grey-border">
      <ReportSectionItem
        label="Associated tags"
        :sub-label="labelTag"
      >
        <div
          class="flex column gap-3"
        >
          <ReportTagMetaBlock
            v-for="(tag, index) in tagMetaData"
            :key="index"
            inline-view
            :meta-data="tag"
          />
        </div>
      </ReportSectionItem>
    </template>
  </div>
</template>

<script>
// Components
import ReportSectionItem from '@/pages/report/components/section-components/ReportSectionItem'
import ReportTagMetaBlock from '@/pages/report/components/section-components/ReportTagMetaBlock'

export default {
  name: 'ReportSectionBody',
  components: {
    ReportSectionItem,
    ReportTagMetaBlock,
  },
  props: {
    labelTop: {
      type: String,
      default: '',
    },
    labelType: {
      type: String,
      default: '',
    },
    labelTag: {
      type: String,
      default: '',
    },
    typeData: {
      type: [Object, String],
      default: () => ({}),
    },
    tagMetaData: {
      type: Array,
      default: () => [],
    },
    ownerName: {
      type: String,
      default: 'Not identified',
    },
    dataDescription: {
      type: String,
      default: '',
    },
  },
}
</script>
