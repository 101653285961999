<template>
  <div :class="{ flex: inlineView }">
    <div
      v-if="metaData && metaData.name"
      class="min-w-250"
      :class="{ 'flex-1': inlineView }"
    >
      <GlTag
        :score="metaData.score"
        :tag="capitalizeFirstLetter(metaData.name)"
      />
    </div>
    <div v-else-if="hideSecondBlock">
      –
    </div>
    <div
      v-if="!hideSecondBlock"
      class="min-w-200"
      :class="{ 'flex-1': inlineView }"
    >
      <div v-if="dataDescription">
        <ReportLinkBlock
          :ellipsis="inlineView"
          label="Source link"
          :url="dataDescription"
        />
      </div>
      <div v-else-if="metaData && metaData.meta">
        <ReportLinkBlock
          :ellipsis="inlineView"
          label="Source link"
          :url="metaData.meta"
        />
      </div>
      <div v-else>
        –
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlTag from '@/components/gl-tag'
import ReportLinkBlock from '@/pages/report/components/section-components/ReportLinkBlock'
// utils
import { capitalizeFirstLetter, isValidURL } from '@/utils/text-formatter'

export default {
  name: 'ReportTagMetaBlock',
  components: {
    GlTag,
    ReportLinkBlock,
  },
  props: {
    metaData: {
      type: Object,
      default: () => ({}),
    },
    dataDescription: {
      type: String,
      default: '',
    },
    inlineView: {
      type: Boolean,
      default: false,
    },
    hideSecondBlock: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    capitalizeFirstLetter,
    isValidURL,
  },
}
</script>
