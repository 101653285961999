<template>
  <div
    class="report-lock-section-wrapper"
    :class="[
      {
        'report-lock-section-owner': owner,
        'report-lock-section-score': score,
        'report-lock-section-type': type,
        'report-lock-section-entity': entity,
        'report-lock-section-full': full,
      },
    ]"
  >
    <transition-group name="fade-leave">
      <div
        v-if="loadingSection || partData.purchased"
        key="loader"
        class="flex align-center justify-center full-height"
      >
        <gl-loader progress-spinner />
      </div>
      <div
        v-else-if="!partData.purchased"
        key="section"
        class="flex column align-center justify-center full-height"
      >
        <div
          v-if="text"
          class="fs-18 line-height-27 text-center bold-600 mb-4 m-fs-16 ml-3 mr-3"
          :style="[{ maxWidth: `${maxTextWidth}` }]"
        >
          {{ text }}
        </div>
        <div>
          <GlButtonPrime
            v-if="showBuyButton"
            class="lock-report-section-button min-w-200 bold-600"
            :class="{
              'lock-report-section-button-hover p-button-success': showHoverOnButton,
              'p-button-warning':
                partData.reportPartName == 'full' ||
                currentHoveredPart.reportPartName == 'full',
            }"
            :disabled="typeOfTag === 'failure'"
            @click="$emit('button-click', partData)"
            @mouseleave="onMouseleaveButton(partData)"
            @mouseover="onMouseoverButton(partData)"
          >
            <transition-group name="fade-leave">
              <div
                v-if="showHoverOnButton"
                :key="partData.reportPartName + 'Included'"
                class="flex justify-center"
              >
                <gl-icon
                  class="mr-2"
                  :height="20"
                  name="done"
                  white
                  :width="20"
                />
                Included
              </div>
              <div
                v-else
                :key="partData.reportPartName + 'thunder'"
                class="flex justify-center"
              >
                {{ `Unlock for ${partData.price || ''}` }}

                <gl-icon
                  v-if="!partData.purchased"
                  class="ml-1 mr-2"
                  :height="20"
                  name="lookups-white"
                  :width="20"
                />
              </div>
            </transition-group>
          </GlButtonPrime>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
// Components
import GlButtonPrime from '@/components/gl-button-prime'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
// Utils
import { ReportPackagesStatusMap } from '@/utils/get-status'
import { getObjectValueByKey } from '@/utils/helpers'

export default {
  name: 'LockReportSection',
  components: {
    GlButtonPrime,
    GlIcon,
    GlLoader,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    maxTextWidth: {
      type: String,
      default: '500px',
    },
    loadingSection: {
      type: Boolean,
      default: true,
    },
    partData: {
      type: Object,
      default: () => ({}),
    },
    showBuyButton: {
      type: Boolean,
      default: true,
    },
    owner: {
      type: Boolean,
      default: false,
    },
    score: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    currentHoveredPart: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ReportPackagesStatusMap,
    }
  },
  computed: {
    showHoverOnButton() {
      return (
        this.currentHoveredPart.includes &&
        this.currentHoveredPart.includes.includes(this.partData.reportPartName)
      )
    },
    typeOfTag() {
      return (
        this.getObjectValueByKey(ReportPackagesStatusMap, this.partData.status) ||
        'success'
      )
    },
  },
  methods: {
    getObjectValueByKey,
    onMouseoverButton(part) {
      this.$emit('update-hovered-part', part)
    },
    onMouseleaveButton() {
      this.$emit('update-hovered-part', {})
    },
  },
}
</script>

<style>
.lock-report-section-button {
  position: relative;
  z-index: 3;
  transition: all 0.5s linear;
}

.lock-report-section-button-hover.lock-report-section-button {
  border-radius: 37px;
  transition: all 0.5s linear;
}

.report-lock-section-wrapper {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.report-lock-section-owner {
  background-image: url('~@/assets/svg/background/report/lock-owner-section.svg');
  height: 260px;
}

.report-lock-section-score {
  background-image: url('~@/assets/svg/background/report/lock-score-section.svg');
  height: 220px;
}
.report-lock-section-type,
.report-lock-section-entity {
  background-image: url('~@/assets/svg/background/report/lock-entity-section.svg');
  height: 350px;
}

.report-lock-section-full {
  background-image: url('~@/assets/svg/background/report/lock-full-section.svg');
  height: 350px;
}

@media (max-width: 1023px) {
  .report-lock-section-owner,
  .report-lock-section-score,
  .report-lock-section-type,
  .report-lock-section-entity,
  .report-lock-section-full {
    background-size: 100%;
    background-size: contain;
  }
  .report-lock-section-type,
  .report-lock-section-entity {
    height: 300px;
  }
  .report-lock-section-full {
    height: 250px;
  }
}
@media (max-width: 767px) {
  .report-lock-section-owner {
    background-image: url('~@/assets/svg/background/report/lock-owner-section-mobile.svg');
  }
  .report-lock-section-score {
    background-image: url('~@/assets/svg/background/report/lock-score-section-mobile.svg');
  }
  .report-lock-section-type,
  .report-lock-section-entity {
    background-image: url('~@/assets/svg/background/report/lock-entity-section-mobile.svg');
    height: 350px;
  }
  .report-lock-section-full {
    background-image: url('~@/assets/svg/background/report/lock-full-section-mobile.svg');
    height: 300px;
    background-size: cover;
  }
}
@media (max-width: 450px) {
  .report-lock-section-type,
  .report-lock-section-entity {
    height: 200px;
  }
}
</style>
