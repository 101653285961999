<template>
  <SelectButton
    v-model="innerValue"
    :data-key="dataKey"
    :multiple="multiple"
    :option-label="optionLabel"
    :options="options"
  >
    <template #option="slotProps">
      <slot
        name="option"
        :slotProps="slotProps"
      />
    </template>
  </SelectButton>
</template>

<script>
//Components
import SelectButton from 'primevue/selectbutton'

export default {
  components: {
    SelectButton,
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    dataKey: {
      type: String,
      default: 'value',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('input', e)
      },
    },
  },
}
</script>

<style>
  
</style>