<template>
  <div
    class="flex t-column m-fullwidth"
  >
    <div class="bold light-grey-59-text min-w-250 t-mb-3 m-mb-2 m-fullwidth m-min-w-100">
      <div
        v-if="label"
        class="fs-18 mb-2 pr-4 max-w-200 m-fs-14"
      >
        {{ label }}
      </div>
      <div
        v-if="subLabel"
        class="fs-12 pr-4 max-w-200 m-fs-10"
      >
        {{ subLabel }}
      </div>
    </div>
    <div class="fullwidth overflow-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSectionItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
  },
}
</script>
