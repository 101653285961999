<template>
  <div
    class="tag"
    :class="[
      { tag__selected: isSelected },
      {
        'tag__score--not-removable':
          score !== undefined && score !== null && !removable,
        'tag__label-type': tagLabelType,
      },
    ]"
    :style="`border-color: ${findColorByTypeScore(
      score,
    )}; background-color: ${hex2rgba(findColorByTypeScore(score), 0.25)}`"
    @click="selectTag"
  >
    <div
      v-if="score !== undefined && score !== null && showScore"
      class="mr-2 tag__score"
      :style="`background-color: ${findColorByTypeScore(score)}`"
    >
      {{ score }}
    </div>
    <gl-icon
      v-if="tagLabelType && tagLabelTypeIcon"
      :height="16"
      :name="tagLabelTypeIconName"
      :success="tagLabelTypeIconClass == 'success'"
      :warn="tagLabelTypeIconClass == 'warn'"
      :width="19"
    />
    <div
      class="ellipsis"
      :class="[
        { 'mr-1': tagLabelType, 'ml-1': score === undefined || score === null },
      ]"
      :style="`max-width: ${maxWidthText};`"
    >
      {{ tag }}
    </div>
    <gl-icon
      v-if="removable"
      class="tag__close-icon"
      :height="16"
      name="tag-close"
      :width="16"
      @click="remove"
    />
  </div>
</template>

<script>
//Components
import GlIcon from '@/components/gl-icon'
// Utils
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'

export default {
  components: {
    GlIcon,
  },
  props: {
    tag: {
      type: String,
      default: '---',
      require: true,
    },
    score: {
      type: Number,
      default: null,
    },
    value: {
      type: [Number, String, Object],
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    maxWidthText: {
      type: String,
      default: '200px',
    },
    tagLabelType: {
      type: Boolean,
      default: false,
    },
    tagLabelTypeIcon: {
      type: Boolean,
      default: false,
    },
    tagLabelTypeIconName: {
      type: String,
      default: 'done',
    },
    tagLabelTypeIconClass: {
      type: String,
      default: '',
    },
    showScore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSelected: false,
    }
  },
  methods: {
    findColorByTypeScore,
    hex2rgba,
    selectTag() {
      this.isSelected = !this.isSelected
    },
    remove() {
      this.$emit('remove', this.value ? this.value : this.tag)
    },
  },
}
</script>

<style scoped>
.tag__close-icon {
  /*margin-right: 2px;*/
  margin-top: 1px;
  float: right;
  cursor: pointer;
  min-width: auto;
}

.tag {
  display: inline-flex;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 20px;
  align-items: center;
  font-weight: 500;
  padding: 1px;
  padding-right: 4px;
  border: 1px solid var(--reflex-bluet);
}
.tag__label-type {
  color: var(--green);
  background: var(--light-green) !important;
  font-size: 16px;
  border-radius: 4px;
  padding: 4px 8px;
  border: none;
}

.tag__score {
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px;
  min-width: 19px;
  text-align: center;
}

.tag__score--not-removable {
  padding-right: 3px;
}

/*.tag__selected {*/
/*  border: 2px solid var(--reflex-bluet);*/
/*}*/
</style>
