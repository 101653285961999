export function getValueByKey(array, key, resultKey) {
  if (!array || (array && !array.length) || !key) return
  const obj = array.find(e => e.name === key)
  return (obj && obj[resultKey]) || ''
}
export function getObjectByKey(array, key, goalKey) {
  if (!array || (array && !array.length) || !key) return
  const obj = array.find(e => e[goalKey] === key)
  return obj || ''
}

export const getObjectValueByKey = (obj, key) => {
  if (!key.toString() || !obj[key]) return ''
  return obj[key]
}

export async function toClipBoard(value, msg = 'Copied!') {
  await navigator.clipboard.writeText(value).then(() => {
    this.$toast.add({
      severity: 'success',
      summary: 'Success Message',
      detail: msg,
      life: 3000,
    })
  })
}

export function deleteByPath(obj, path) {
  if (typeof path === 'string') {
    path = path.split('.')
  }

  if (path.length === 1 && !path[0].includes('[]')) {
    delete obj[path[0]]
    return obj
  }

  if (path[0].includes('[]')) {
    const arrayName = path[0].split('[]')[0]
    const targetName = path[0].split('[]')[1]

    if (
      arrayName &&
      obj[arrayName] &&
      Array.isArray(obj[arrayName]) &&
      obj[arrayName].length
    ) {
      obj[arrayName] = obj[arrayName].map(el => deleteByPath(el, targetName))
    }

    // eslint-disable-next-line no-prototype-builtins
  } else if (obj.hasOwnProperty(path[0]) && typeof obj[path[0]] === 'object') {
    deleteByPath(obj[path[0]], path.slice(1))
    if (Object.keys(obj[path[0]]).length === 0) {
      delete obj[path[0]]
    }
  }

  return obj
}
