<template>
  <div class="token-report mb-4">
    <div class="token-report__header fs-16 bold-700 mb-2">
      Requested Token Report
    </div>
    <div class="token-report__content flex flex-wrap gap-3">
      <gl-button
        dark
        style="height: 38px"
        title="CHOOSE TOKEN"
        @click="chooseTokenShow"
      />
      <RequestTokenReportItem 
        v-for="item in firstTokens" 
        :key="item.address"
        :is-active="item.address === getActiveToken.address"
        :token="item"
        @clickOnItem="changeToken"
      />
    </div>
    <ChooseTokenModal 
      v-model="chooseTokenModalShow"
      :all-tokens="sortedTokens"
      @chooseTokenFromModal="changeToken"
      @closeChooseModal="chooseTokenShow"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestTokenReportItem from './RequestTokenReportItem.vue'
import ChooseTokenModal from './ChooseTokenModal.vue'
import GlButton from '@/components/gl-button'

export default {
  components: {
    RequestTokenReportItem,
    ChooseTokenModal,
    GlButton,
  },
  props: {
    options: {
      type: Object,
      default() {
        () => []
      },
    },
    numberOfFirstTokens: {
      type: Number,
      default: 6,
    }
  },
  data() {
    return {
      chooseTokenModalShow: false
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveToken']),
    sortedTokens() {
      const tokensArray = Object.values(this.options)

      return tokensArray.slice().sort((a, b) => {
        if (a === tokensArray[tokensArray.length - 1]) return -1;
        if (b === tokensArray[tokensArray.length - 1]) return 1;
        if (a.stableCoin && !b.stableCoin) return -1;
        if (!a.stableCoin && b.stableCoin) return 1;
        if (a.icon && !b.icon) return -1;
        if (!a.icon && b.icon) return 1;
        if (a.symbol < b.symbol) return -1;
        if (a.symbol > b.symbol) return 1;

        return 0;
      });
    },
    firstTokens() {
      return this.sortedTokens
        .filter(token => token.symbol.trim() !== '')
        .slice(0, this.numberOfFirstTokens)
    }
  },
  methods: {
    changeToken(token) {
      this.$emit('newToken', token)
    },
    chooseTokenShow() {
      this.chooseTokenModalShow = !this.chooseTokenModalShow
    },
  },
}
</script>