<template>
  <Button
    class="gl-button-prime"
    :class="[{ 'p-button-outlined': outlined }]"
    :disabled="disabled"
    :label="title"
    :loading="loading"
    @click="loading ? undefined : $emit('click')"
    @mouseleave="$emit('mouseleave')"
    @mouseover="$emit('mouseover')"
  >
    <div v-if="loading">
      <i
        class="pi pi-spin pi-spinner"
        style="font-size: 20px"
      />
    </div>
    <slot v-else />
  </Button>
</template>

<script>
//Components
import Button from 'primevue/button'

export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '-',
    },
    buttonStyle: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.gl-button-prime.p-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
