<template>
  <section
    class="gl-section-wrapper"
    :class="{
      'gl-section-wrapper--green': green,
      'gl-section-wrapper--warning': warning,
    }"
  >
    <div
      class="gl-section-title"
      :class="{
        'gl-section-title--green': green,
        'gl-section-title--warning': warning,
      }"
    >
      <slot name="title" />
    </div>
    <div class="gl-section-body-wrapper m-fullwidth">
      <slot name="body" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'GlSection',
  props: {
    green: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.gl-section-wrapper {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--pale-blue);
}
.gl-section-wrapper--green {
  border: 1px solid var(--green);
}
.gl-section-wrapper--warning {
  border: 1px solid var(--pale-orange);
}

.gl-section-title {
  display: flex;
  margin-bottom: 16px;
  color: var(--space-cadet);
  font-size: 24px;
  font-weight: 700;
}

.gl-section-title--green {
  background: var(--green);
  transition: all 0.5s linear;
}
.gl-section-title--warning {
  background: var(--pale-orange);
  transition: all 0.5s linear;
}

.gl-section-body-wrapper:has(.report-lock-section-wrapper),
.gl-section-body-wrapper:has(.report-lock-section-owner),
.gl-section-body-wrapper:has(.report-lock-section-address-info),
.gl-section-body-wrapper:has(.type-entity-wrapper),
.gl-section-body-wrapper:has(.source-table-wrapper) {
  padding: 0;
}

@media (max-width: 767px) {
  .gl-section-title {
    font-size: 14px;
  }
  .gl-section-body-wrapper {
    padding: 16px;
  }
}
</style>
