<template>
  <gl-modal
    v-bind="$attrs"
    closable
    full-buttons
    :loading="loading"
    submit-title="Yes"
    :title="title"
    width="520"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('confirm')"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template #mainSlot>
      <slot name="mainSlot" />
    </template>
    <template #content>
      <slot name="content" />
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
