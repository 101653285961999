<template>
  <div class="page-wrapper">
    <div
      v-if="title"
      class="page-header-wrapper"
    >
      <h1 class="page-title">
        {{ title }}
      </h1>
      <slot name="actions" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
  },
}
</script>
